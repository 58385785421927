import { useLocation } from "react-router-dom"

const SOURCE_MAP = {
    'homepage': "Homepage",
    'participation': "Participation",
    'portfolio': "Portfolio"
} as {[k in string]: string}

export const useSource = () => {
    const location = useLocation()
    return {
        main: SOURCE_MAP[location.pathname.split("/")[1]]
    }
}
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import Banner from './Banner'
import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import ProjectTabs from './tabs'
import {
  IdoStatus,
  useFetchAllLaunchesQuery,
  useFetchOneIdoQuery,
  useFetchOneProjectByNameQuery,
} from '../../services/modules/project'
import Lottie from 'lottie-react'
import LogoAnimation from '../../animations/logo loading white.json'
import {
  useFetchTotalParticipantsQuery,
  useLazyFetchSingleParticipationQuery,
} from '../../services/modules/participations'
import { scrollToTop } from '../../utils'
import { PROJECT_TYPE_DICTIONARY } from '../../constants/application'
import { useAccount } from 'wagmi'
import TimeboxList from './TimeboxList'
import ProjectDetails from './ProjectDetails'
import TokenTable from './TokenTable'
import ParticipationBox from './ParticipationBox'
import SalesCard from '../../components/SalesCard'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import SalesTabs from './SalesTabs'
import IDODetails from './IDODetails'
import NewSelectProjectModal from '../../components/NewSelectProjectModal'
import ChainChangeModal from '../../components/ChainChangeModal'
import InspectInvestors from './inspect_investors.json'
import { useAppSelector } from '../../hooks'
import ClaimWrapper from './ClaimWrapper'

import  * as amplitude from '@amplitude/analytics-browser';
import { useParticipationEntryPoint } from '../../Context'

const RevampedOverview: React.FC = () => {
  const params = useParams()
  let projectName = params.projectName || ''
  const navigate = useNavigate()
  const theme = useTheme()

  const isDown768 = useMediaQuery(theme.breakpoints.down(768))
  const isDown1200 = useMediaQuery(theme.breakpoints.down(1200))
  const isDown1240 = useMediaQuery(theme.breakpoints.down(1240))

  const launchesPerPage = 4
  const [visibleLaunches, setVisibleLaunches] = useState(launchesPerPage)

  const [showProjectSelectionModal, setShowProjectSelectionModal] =
    useState(false)

  const handleShowMore = () => {
    setVisibleLaunches(
      prevVisibleLaunches => prevVisibleLaunches + launchesPerPage,
    )
  }

  const location: any = useLocation()

  // get query params
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('claim')) {
      // setMainTab(2) after 1 second
      setTimeout(() => {
        setMainTab(2)
      }, 1000)
    }
  }, [searchParams])

  const { address, chain } = useAccount()

  const [mainTab, setMainTab] = React.useState(0)
  const handleMainTabChange = (value: number) => {
    setMainTab(value)
  }

  const [mainSaleTab, setMainSaleTab] = React.useState(0)
  const handleMainSaleTabChange = (value: number) => {
    setMainSaleTab(value)
  }
  //if project name has underscore, replace with %20
  projectName = projectName.replace(/_/g, '%20')
  const { data, isFetching, isError, isLoading, error } =
    useFetchOneProjectByNameQuery(projectName, { refetchOnFocus: false })

  const [
    fetchParticipation,
    { data: foundParticipation, isLoading: foundParticipationLoading },
  ] = useLazyFetchSingleParticipationQuery()

  const { projectLinkSource } = useParticipationEntryPoint()


  const {
    data: totalParticipants,

    isLoading: totalParticipantsLoading,
  } = useFetchTotalParticipantsQuery(
    {
      projectId: data ? data._id : '0',
      idoNumber: 0,
    },
    {
      skip: !data,
      pollingInterval: 1500000,
    },
  )

  const {
    data: idoData,

    isLoading: idoDataLoading,
  } = useFetchOneIdoQuery(
    {
      projectId: data ? data._id : '0',

      idoNumber: 0,
    },
    {
      skip: !data,
    },
  )

  const {
    data: allLaunchesData,
    isLoading: allLaunchesLoading,
    isError: allLaunchesError,
  } = useFetchAllLaunchesQuery()

  const mainAppToken = useAppSelector((state: any) => state.user.mainAppToken)

  useEffect(() => {
    if (data && address && idoData && mainAppToken) {
      setTimeout(() => {
        fetchParticipation({
          projectId: data._id,
          idoNumber: 0,
          address: address,
        })
      }, 500)
    }
  }, [address, data, idoData, address, mainAppToken])

  useEffect(() => {
    scrollToTop()
  }, [location])

  useEffect(() => {
    const referrer = document.referrer;
    const params = new URLSearchParams(location.search);
    const referral_code = params.get('code') || '';
    if (data?.name) {
      amplitude.track('ProjectPageOpen', {
        timestamp: new Date().toISOString(),
        referrer,
        project_name: data.name.toLowerCase().replace(" ", "_"),
        project_link_source: referral_code ? "Referral" : projectLinkSource,
        referral_code: referral_code,
        project_type: idoData?.amountToRaise && idoData?.amountToRaise > 0 ? PROJECT_TYPE_DICTIONARY.IDO : PROJECT_TYPE_DICTIONARY.AIRDROP
      });
    }
  }, [data?.name])

  if (!data)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '50vh',
          height: '100vh',
        }}
      >
        <Lottie
          animationData={LogoAnimation}
          loop={true}
          style={{
            height: '200px',
            // width: '100vw',
          }}
        />
      </Box>
    )

  let showClaim = Boolean(
    address &&
      idoData &&
      (idoData.status === IdoStatus.TGE ||
        idoData.status === IdoStatus.CANCELLED) &&
      ((idoData._id === '6554d8e0bf282065fe95f222' &&
        InspectInvestors.find(
          (investor: any) => investor.toLowerCase() === address.toLowerCase(),
        )) ||
        Boolean(
          !foundParticipationLoading &&
            foundParticipation &&
            foundParticipation.isInvestor,
        )),
  )

  console.log('allLaunchesData', allLaunchesData)
  return (
    <Box
      marginTop={'60px'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      pb={12}
    >
      <Grid
        sx={{
          width: isDown1240 ? `calc(${window.innerWidth}px - 40px)` : '100%',
          maxWidth: '1200px',
        }}
        display={'flex'}
        flexDirection={'column'}
      >
        {projectName.toLocaleLowerCase() !== 'inspect' && (
          <TimeboxList data={data.timeline} />
        )}

        <Grid container justifyContent={'space-between'} spacing={'24px'}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={8}
            flexDirection={'column'}
            height={'max-content'}
          >
            <Banner
              socialLinks={data.socialCommunityLinks}
              website={data.website}
              name={data.name}
              intro={data.introduction}
              symbol={data.token.symbol}
              projectImageUrl={data.projectImageUrl}
              logoUrl={data.logoUrl}
              tags={data.tags}
            />

            {isDown1200 && (
              <ParticipationBox
                id={data._id}
                ido={idoData}
                name={data.name}
                showTickets={mainSaleTab === 1}
                time={
                  idoData?.status === IdoStatus.PARTICIPATION
                    ? idoData?.endDate
                    : idoData?.status === IdoStatus.PROCESSING
                    ? idoData?.investmentStartDate
                    : idoData?.status === IdoStatus.INVESTMENT
                    ? idoData?.investmentEndDate
                    : idoData?.endDate
                }
              />
            )}

            <ProjectTabs
              tab={mainTab}
              handleTabChange={handleMainTabChange}
              showClaim={showClaim}
              showIdoDetails={projectName.toLowerCase() !== 'inspect'}
            />

            {mainTab === 0 && (
              <>
                <ProjectDetails projectData={data} />
                {isDown1200 && (
                  <Grid item xs={12} container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      smd={12}
                      md={12}
                      lg={2}
                      paddingTop={'0px !important'}
                    />
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      smd={12}
                      md={12}
                      lg={10}
                      paddingTop={'0px !important'}
                    >
                      <TokenTable tokenMetrics={data.token.metrics} />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {mainTab === 1 && (
              <IDODetails
                idoData={idoData}
                token={data.token}
                timeline={data.timeline}
                totalParticipants={totalParticipants}
              />
            )}
            {mainTab === 2 && showClaim && (
              <ClaimWrapper
                idoData={idoData}
                project={data}
                closeClaimTab={() => setMainTab(0)}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div
              style={{
                position: isDown1200 ? 'static' : 'sticky',
                top: '80px',
                paddingBottom: isDown1200 ? '0' : '93px',
              }}
            >
              {!isDown1200 && (
                <ParticipationBox
                  id={data._id}
                  ido={idoData}
                  name={data.name}
                  showTickets={mainSaleTab === 1} //need contract integration here
                  time={
                    idoData?.status === IdoStatus.PARTICIPATION
                      ? idoData?.endDate
                      : idoData?.status === IdoStatus.PROCESSING
                      ? idoData?.investmentStartDate
                      : idoData?.status === IdoStatus.INVESTMENT
                      ? idoData?.investmentEndDate
                      : idoData?.endDate
                  }
                />
              )}
              <Divider
                sx={{
                  backgroundColor: '#1D1F21',
                  height: '1px',
                  mt: '24px',
                  mb: '48px',
                  display: isDown1200 ? 'block' : 'none',
                }}
              />
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={isDown1200 ? 'column' : 'row'}
                mt={'48px'}
                mb={'24px'}
                alignItems={'center'}
                gap={'16px'}
              >
                <Typography
                  sx={{
                    fontFamily: 'Hellix',
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#FFFFFF',
                    margin: '0px',
                    lineHeight: '31.2px',
                    width: isDown1200 ? '100%' : 'fit-content',
                  }}
                >
                  Other Sales
                </Typography>

                {/* <SalesTabs
                tab={mainSaleTab}
                handleTabChange={handleMainSaleTabChange}
              /> */}
              </Box>
              {allLaunchesData
                ?.filter((launch: any) => {
                  const isUpcoming =
                    launch.type === 'upcoming' || launch.type === 'featured'
                  // const isLive =
                  //   launch.type !== 'completed' && launch.type !== 'upcoming'
                  const isDifferentID = launch._id !== idoData?.project?._id
                  const decodedProjectName = decodeURIComponent(projectName)
                  const formattedLaunchRouteName = launch.routeName.replace(
                    /_/g,
                    ' ',
                  )

                  const isDifferentFromParams =
                    formattedLaunchRouteName !== decodedProjectName

                  return (
                    isDifferentID && isDifferentFromParams && isUpcoming
                    // (mainSaleTab === 0 ? isLive : isUpcoming)
                  )
                })
                ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                ?.slice(0, visibleLaunches)
                .map((launch: any) => (
                  <SalesCard
                    key={launch._id}
                    heading={launch.projectTitle}
                    titleImage={launch.logoUrl}
                    totalRaise={launch.totalRaise}
                    startsIn={launch.startsIn}
                    registrationStarted={launch.registrationStarted}
                    registrationEndDate={launch.registrationEndDate}
                    routeName={launch.routeName}
                  />
                ))}
              {allLaunchesData &&
                visibleLaunches < allLaunchesData.length &&
                allLaunchesData.filter(
                  (launch: any) => launch.type === 'upcoming',
                ).length > 4 && (
                  <OutlinedButton
                    // onClick={handleShowMore}
                    onClick={() => setShowProjectSelectionModal(true)}
                    sx={{
                      height: '41px',
                      width: '100%',
                    }}
                  >
                    Show More
                  </OutlinedButton>
                )}
            </div>
          </Grid>
          {!isDown1200 && mainTab === 0 && (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={2}
                paddingTop={'0px !important'}
              />
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={10}
                paddingTop={'0px !important'}
              >
                <TokenTable tokenMetrics={data.token.metrics} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <NewSelectProjectModal
        open={showProjectSelectionModal}
        handleClose={() => setShowProjectSelectionModal(false)}
        onSelection={(selectedProject: string) => {
          setShowProjectSelectionModal(false)
          navigate(`/project/${selectedProject}`)
        }}
        projectList={
          allLaunchesData
            ? allLaunchesData?.filter((launch: any) => {
                const isUpcoming =
                  launch.type === 'upcoming' && launch.registrationStarted
                // const isLive =
                //   launch.type !== 'completed' && launch.type !== 'upcoming'
                const isDifferentID = launch._id !== idoData?.project?._id
                const isDifferentFromParams = launch.routeName !== projectName
                return (
                  isDifferentID && isDifferentFromParams && isUpcoming
                  // (mainSaleTab === 0 ? isLive : isUpcoming)
                )
              })
            : []
        }
      />
    </Box>
  )
}

export default RevampedOverview

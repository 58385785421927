export const APPLICATION_URL = window.location.origin
export const APPLICATION_NAME = 'Ape Launchpad'

export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'https://api-ng.apeterminal.io/api'
//export const SERVER_URL = 'http://localhost:3005/api/'

export const USDC_ADDRESS = '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
//export const USDC_ADDRESS = '0xCf8EA1c5a4421a01bc769D556Fa0f01ebd0523a2'

// gulf countries are not allowed to join the sales
export const IS_GCC_WEBSITE = window.location.hostname === "gcc.apeterminal.io";

// export const RPC_URL = {
//   BSC:  "https://bsc.blockpi.network/v1/rpc/public",
//   ETH: "https://ethereum.blockpi.network/v1/rpc/public",
//   ARB: "https://arbitrum.blockpi.network/v1/rpc/public",
//   AVAX: "https://avalanche.blockpi.network/v1/rpc/public",
// };

export const RPC_URL = {
  BSC:  "https://rpc.ankr.com/bsc",
  ETH: "https://rpc.ankr.com/eth",
  ARB: "https://rpc.ankr.com/arbitrum",
  AVAX: "https://rpc.ankr.com/avalanche",
};

export const CONTRACT_ADDRESS = {
  APE_INVESTMENT_BSC: "0x80614F814917368Dd9Dd331F037fC600c6e31063",
  APE_INVESTMENT_ETH: "0x6B02dA8eE04494B324Cb400D6d8Cfac239d4FDc5",
}

export const WALLET_CONNECT_PROJECT_ID = "1a7e8d507a1b268947b7a23c971a35b3";

// we try to make less database changes as possible on the migration to Azure from AWS
// so will only do a manual endpoint swap on frontend
export const ASSETS_CDN_ENDPOINT_DEPRECATED = "https://launchpad-bucket.s3.amazonaws.com";
export const ASSETS_CDN_ENDPOINT = "https://cdn.apeterminal.io";

import { Box, TableRow, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FixedBodyTableCell, StyledBodyTableCell } from './MySalesTable'
import { replaceUnderscoresWithSpaces, getReplacedCdnEndpoint } from '../../utils'
import { useAccount } from 'wagmi'
import { ethers } from 'ethers'
import ClaimNewAbi from '../../contracts/claim_new.json'
import { useNavigate } from 'react-router-dom'
import { RPC_URL } from '../../constant'

const ClaimRow = ({ sale, index }: any) => {
  const isDown800 = useMediaQuery('(max-width:800px)')

  const { address } = useAccount()
  const navigate = useNavigate()

  console.log('ClaimRow', sale)
  const [claimableAmount, setClaimableAmount] = useState(0)

  const [claimedAmount, setClaimedAmount] = useState(0)

  useEffect(() => {
    console.log('ClaimRow useEffect', sale.ido.claimNetwork)
    ;(async () => {
      try {
        if (
          !sale.ido?.claimContract ||
          (sale.ido.claimNetwork !== 'BSC' &&
            sale.ido.claimNetwork !== 'ETH' &&
            sale.ido.claimNetwork !== 'ARB' &&
            sale.ido.claimNetwork !== 'AVAX')
        )
          return

        let provider

        if (sale.ido.claimNetwork === 'BSC') {
          console.log('calling BSC')
          // initialize ethers provider
          provider = new ethers.providers.JsonRpcProvider(RPC_URL.BSC)
        } else if (sale.ido.claimNetwork === 'ETH') {
          console.log('calling ETH')
          // initialize ethers provider
          provider = new ethers.providers.JsonRpcProvider(RPC_URL.ETH)
        } else if (sale.ido.claimNetwork === 'ARB') {
          console.log('calling ARB')
          // initialize ethers provider
          provider = new ethers.providers.JsonRpcProvider(RPC_URL.ARB)
        } else if (sale.ido.claimNetwork === 'AVAX') {
          console.log('calling AVAX')
          provider = new ethers.providers.JsonRpcProvider(RPC_URL.AVAX)
        }

        // initialize claim contract
        const claimContract = new ethers.Contract(
          sale.ido?.claimContract,
          ClaimNewAbi,
          provider,
        )

        // get decimals
        let tokenDecimals = await claimContract.returnTokenDecimal()

        // get claimable amount
        let claimableAmount = await claimContract.getClaimableAmount(address)

        // set claimable amount
        setClaimableAmount(
          Number(
            ethers.utils.formatUnits(
              claimableAmount.toString(),
              Number(tokenDecimals),
            ),
          ),
        )

        // get claimedAmount
        let claimedAmount = await claimContract.claimedAmount(address)

        // set claimed amount
        setClaimedAmount(
          Number(
            ethers.utils.formatUnits(
              claimedAmount.toString(),
              Number(tokenDecimals),
            ),
          ),
        )
      } catch (error) {
        console.log('error', error)
      }
    })()
  }, [sale?.ido?.claimContract, sale?.ido?.claimNetwork, address])

  return (
    <TableRow
      key={index}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#000000',
        },
      }}
      onClick={() => navigate(`/project/${sale.ido.project.name}`)}
    >
      {isDown800 ? (
        <FixedBodyTableCell>
          <img
            src={getReplacedCdnEndpoint(sale.ido.project.logoUrl)}
            alt={sale.ido.project.name}
            style={{ width: 40, height: 40, borderRadius: 10 }}
          />
        </FixedBodyTableCell>
      ) : (
        <StyledBodyTableCell>
          <Box display="flex" alignItems="center">
            <img
              src={getReplacedCdnEndpoint(sale.ido.project.logoUrl)}
              alt={sale.ido.project.name}
              style={{ width: 40, height: 40, borderRadius: 10 }}
            />
            <Box ml={'12px'}>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontSize: '15px',
                  fontWeight: 400,
                }}
              >
                {replaceUnderscoresWithSpaces(sale.ido.project.name)}
              </Typography>
              <Typography
                sx={{
                  color: '#7E8180',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
              >
                {sale.ido.project.token.symbol}
              </Typography>
            </Box>
          </Box>
        </StyledBodyTableCell>
      )}
      <StyledBodyTableCell>
        {Number(claimableAmount.toFixed(2)).toLocaleString('en')}{' '}
        {sale.ido.project.token.symbol}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {Number(sale.ido.totalTokens).toLocaleString('en')}{' '}
        {sale.ido.project.token.symbol}
      </StyledBodyTableCell>
      <StyledBodyTableCell>
        {Number(claimedAmount.toFixed(2)).toLocaleString('en')}{' '}
        {sale.ido.project.token.symbol}
      </StyledBodyTableCell>
      <StyledBodyTableCell>N/A</StyledBodyTableCell>
      <StyledBodyTableCell>
        <Box
          sx={{
            height: '28px',
            width: '69px',
            borderRadius: '80px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Inter',
            border: '1px solid #313335',
          }}
          onClick={e => {
            e.stopPropagation()
            navigate(`/project/${sale.ido.project.name}?claim=true`)
          }}
        >
          Claim
        </Box>
      </StyledBodyTableCell>
    </TableRow>
  )
}

export default ClaimRow

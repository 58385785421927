import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { TableProjectLogo } from '../../assets'
import { useFetchAllLaunchesQuery } from '../../services/modules/project'
import { useFetchUserAllParticipationsQuery } from '../../services/modules/participations'
import { useNavigate } from 'react-router-dom'
import { getReplacedCdnEndpoint } from '../../utils'

export const StyledHeadTableCell = styled(TableCell)({
  backgroundColor: '#000000',
  borderBottom: '1px solid #0F1113',
  color: '#767676',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  height: '48px',
  flexShrink: 1,
  padding: '0px 20px',
  whiteSpace: 'nowrap',
})

export const FixedHeadTableCell = styled(StyledHeadTableCell)({
  position: 'sticky',
  left: 0,
  zIndex: 99,
  boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.5)',
  borderRight: '1px solid #1D1F21',
})

export const StyledBodyTableCell = styled(TableCell)({
  height: '72px',
  borderBottom: '1px solid #1D1F21',
  color: '#B1B4B3',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  flexShrink: 1,
  whiteSpace: 'nowrap',
  padding: '0px 20px',
  maxHeight: '56px',
  lineHeight: '15px',
})

export const FixedBodyTableCell = styled(StyledBodyTableCell)({
  position: 'sticky',
  left: 0,
  zIndex: 1,
  backgroundColor: '#0F1113',
  textAlign: 'center',
  boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.5)',
  borderRight: '1px solid #1D1F21',
})

const mySales = [
  {
    projectLogo: TableProjectLogo,
    projectName: 'AIT Protocol',
    projectSymbol: 'AIT',
    score: 4.5,
    participants: 186830,
    connectedAmount: 873782727.76,
    referralAmount: 36.7,
    status: 'won',
  },
  {
    projectLogo: TableProjectLogo,
    projectName: 'Project 2',
    projectSymbol: 'P2',
    score: 4.2,
    participants: 150000,
    connectedAmount: 500000000,
    referralAmount: 25.5,
    status: 'won',
  },
  {
    projectLogo: TableProjectLogo,
    projectName: 'Project 3',
    projectSymbol: 'P3',
    score: 4.8,
    participants: 250000,
    connectedAmount: 1000000000,
    referralAmount: 50.2,
    status: 'registered',
  },
  {
    projectLogo: TableProjectLogo,
    projectName: 'Project 4',
    projectSymbol: 'P4',
    score: 4.6,
    participants: 175000,
    connectedAmount: 750000000,
    referralAmount: 42.9,
    status: 'investment',
  },
  {
    projectLogo: TableProjectLogo,
    projectName: 'Project 5',
    projectSymbol: 'P5',
    score: 4.4,
    participants: 200000,
    connectedAmount: 900000000,
    referralAmount: 30.8,
    status: 'ended',
  },
]

const getStatusStyle = (status: string) => {
  switch (status) {
    case 'won':
      return {
        backgroundColor: 'rgba(99, 234, 113, 0.1)',
        color: '#63EA71',
      }
    case 'invested':
      return {
        backgroundColor: 'rgba(255, 119, 34, 0.1)',
        color: '#FF7722',
      }
    case 'registered':
      return {
        backgroundColor: 'rgba(244, 253, 171, 0.1)',
        color: '#F4FDAB',
      }
    case 'ended':
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#B1B4B3',
      }
    default:
      return {
        backgroundColor: 'rgba(29, 31, 33, 1)',
        color: '#B1B4B3',
      }
  }
}

const MySalesTable = ({ mySales }: any) => {
  const isDown800 = useMediaQuery('(max-width:800px)')
  console.log('mySales', mySales)

  const navigate = useNavigate()

  return (
    <Box width={'100%'} mb={'60px'}>
      <Box
        width={'100%'}
        maxWidth={'1200px'}
        display={'flex'}
        flexDirection={'column'}
        paddingLeft={'0px'}
        paddingTop={'0px'}
      >
        <TableContainer
          component={Paper}
          sx={{
            height: '100%',
            maxHeight: '414px',
            border: '1px solid #1D1F21',
            boxShadow: 'none',
            backgroundImage: 'none',
            flexShrink: 1,
            width: '100%',
            borderRadius: '16px',
            '&::-webkit-scrollbar': {
              display: 'flex',
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#313335',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#0F1113',
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              minHeight: mySales.length <= 5 ? '100%' : '408px',
              height: '100%',
              flexShrink: 0,
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  textWrap: 'nowrap',
                }}
              >
                {isDown800 ? (
                  <FixedHeadTableCell>Projects</FixedHeadTableCell>
                ) : (
                  <StyledHeadTableCell>Projects</StyledHeadTableCell>
                )}
                {/* <StyledHeadTableCell>Score</StyledHeadTableCell> */}
                <StyledHeadTableCell>Participants</StyledHeadTableCell>
                <StyledHeadTableCell>Connected</StyledHeadTableCell>
                {/* <StyledHeadTableCell>Referral</StyledHeadTableCell> */}
                <StyledHeadTableCell>Status</StyledHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflowY: 'hidden',
                overflowX: 'auto',
                backgroundColor: '#0F1113',
              }}
            >
              {mySales.map(({ ido, isWinner, isInvestor }: any, index: any) => (
                <TableRow
                  key={index}
                  onClick={() => navigate(`/project/${ido.project.name}`)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#000000',
                    },
                  }}
                >
                  {isDown800 ? (
                    <FixedBodyTableCell>
                      <img
                        src={getReplacedCdnEndpoint(ido.project.logoUrl)}
                        alt={ido.project.name}
                        style={{ width: 40, height: 40, borderRadius: 10 }}
                      />
                    </FixedBodyTableCell>
                  ) : (
                    <StyledBodyTableCell>
                      <Box display="flex" alignItems="center">
                        <img
                          src={getReplacedCdnEndpoint(ido.project.logoUrl)}
                          alt={ido.project.name}
                          style={{ width: 40, height: 40, borderRadius: 10 }}
                        />
                        <Box ml={'12px'}>
                          <Typography
                            sx={{
                              color: '#FFFFFF',
                              fontSize: '15px',
                              fontWeight: 400,
                            }}
                          >
                            {ido.project.name}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#7E8180',
                              fontSize: '12px',
                              fontWeight: 400,
                            }}
                          >
                            {ido.project.token.symbol}
                          </Typography>
                        </Box>
                      </Box>
                    </StyledBodyTableCell>
                  )}
                  {/* <StyledBodyTableCell>0</StyledBodyTableCell> */}
                  <StyledBodyTableCell>
                    {ido.totalParticipation.toLocaleString('en')}
                  </StyledBodyTableCell>
                  <StyledBodyTableCell>
                    ${ido.totalAssetsConnected.toLocaleString('en')}
                  </StyledBodyTableCell>
                  {/* <StyledBodyTableCell>0x</StyledBodyTableCell> */}
                  <StyledBodyTableCell>
                    <Box
                      sx={{
                        height: '28px',
                        width: '88px',
                        borderRadius: '80px',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                        fontSize: '12px',
                        fontWeight: 500,
                        fontFamily: 'Inter',

                        ...getStatusStyle(
                          isInvestor
                            ? 'invested'
                            : isWinner
                            ? 'won'
                            : 'registered',
                        ),
                      }}
                    >
                      {/* make first letter of status capital */}
                      {/* {ido.status.charAt(0).toUpperCase() + ido.status.slice(1)} */}
                      {isInvestor
                        ? 'Invested'
                        : isWinner
                        ? 'Won'
                        : 'Registered'}
                    </Box>
                  </StyledBodyTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default MySalesTable

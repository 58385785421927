import {
  Box,
  Button,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material'

import GreenButton from '../GreenButton'
import Countdown from 'react-countdown'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  EndsIn,
  GetNotified,
  MarketMaker,
  SaleType,
  TotalRaise,
} from '../../assets'
import zIndex from '@mui/material/styles/zIndex'
import { useParticipationEntryPoint } from '../../Context'
import { getReplacedCdnEndpoint } from '../../utils'

interface IUpcomingLaunchCard {
  image: string
  heading: string
  titleImage: string
  chips?: string[]
  totalRaise?: number
  startsIn?: number
  saleType?: string
  marketMaker?: string
  buttonText?: string
  timePassed?: boolean
  comingSoon?: boolean
  registrationStarted?: boolean
  active?: boolean
  type?: string
  videoUrl?: string
  registrationEndDate?: string
  routeName?: string
  started?: boolean
  backedByLogo?: any
}

const NewUpcomingLaunchCard: React.FC<IUpcomingLaunchCard> = ({
  image,
  heading,
  titleImage,
  chips,
  totalRaise,
  startsIn,
  saleType,
  marketMaker,
  routeName,
  buttonText,
  comingSoon,
  registrationStarted,
  active,
  type,
  videoUrl,
  registrationEndDate,
  started,
  backedByLogo,
}) => {
  const [timePassed, setTimePassed] = useState(false)

  const navigate = useNavigate()

  const isDown449 = useMediaQuery('(max-width:449px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown1152 = useMediaQuery('(max-width:1152px)')

  // run useEffect every second
  useEffect(() => {
    if (!startsIn) return
    const interval = setInterval(() => {
      if (startsIn && startsIn <= new Date().getTime()) {
        setTimePassed(true)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [startsIn])

  const { setProjectLinkSource } = useParticipationEntryPoint()
  return (
    <Box
      sx={{
        background:
          'linear-gradient(142.67deg, rgba(255, 185, 0, 0.3) 0%, rgba(255, 255, 255, 0.09) 51.04%, rgba(255, 185, 0, 0.3) 100%)',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        flex: 1,

        minWidth: isDown449
          ? `calc(${window.innerWidth}px - 60px)`
          : isDown767
          ? '278px'
          : '350px',
        maxWidth: isDown449
          ? `calc(${window.innerWidth}px - 60px)`
          : isDown767
          ? '350px'
          : '390px',
      }}
    >
      <Box
        sx={{
          background:
            'radial-gradient(60.16% 54.61% at 50% 116.08%, rgba(255, 185, 0, 0.08) 0%, rgba(255, 185, 0, 0) 100%), #0F1113',
          borderRadius: '16px',
          border: 'none',
          width: `calc(100% - 1px)`,
          margin: '0.5px',
          transition: 'all 0.3s',
          position: 'relative',
          '&:hover > .launch-card-hover-bg': {
            opacity: '1 !important',
          },
        }}
      >
        <div
          className="launch-card-hover-bg"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
            borderRadius: '16px',
            transition: 'all 0.3s',
            opacity: 0,
            pointerEvents: 'none',
            userSelect: 'none',
            background:
              'radial-gradient(60.16% 54.61% at 50% 116.08%, rgba(255, 185, 0, 0.14) 0%, rgba(255, 185, 0, 0) 100%), #0F1113',
          }}
        />
        {/* {
        type === 'coming-soon' && heading === 'Coming Soon' ? (
        <Box
          position={'relative'}
          sx={{
            width: '100%',
            height: '250px',
            overflow: 'hidden',
          }}
        >
          <img
            src={image}
            style={{
              width: '100%',
              height: 250,
            }}
            alt="card-bg"
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backdropFilter: 'blur(8px)',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Logo}
              style={{
                height: '82px',
              }}
              alt="logo"
            />
          </Box>
        </Box>
      ) : ( */}
        <>
          {videoUrl && !isDown1152 ? (
            <Box
              position={'relative'}
              sx={{
                width: '100%',
                // height: 250,
                height: '219px',
                overflow: 'hidden',
                display: 'flex',
                zIndex: 1,
                borderRadius: '12px',
              }}
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                style={{
                  width: `calc(100% - 16px)`,
                  marginTop: '8px',
                  marginRight: '8px',
                  marginLeft: '8px',
                  objectFit: 'cover',
                  borderRadius: '12px',
                  height: '209px',
                }}
                poster={image}
              >
                <source src={getReplacedCdnEndpoint(videoUrl)} type="video/mp4" />
              </video>
              {type !== 'coming-soon' && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    padding: '8px',
                    zIndex: 1,
                  }}
                >
                  <Grid container>
                    {chips?.map((chip, index) => (
                      <ChipText key={index} text={chip} color={'#B1B4B3'} />
                    ))}
                  </Grid>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              position={'relative'}
              sx={{
                width: '100%',
                // height: 250,
                height: '219px',
                overflow: 'hidden',
                display: 'flex',
                zIndex: 1,
                borderRadius: '12px',
              }}
            >
              <img
                src={image}
                style={{
                  width: `calc(100% - 16px)`,
                  marginTop: '8px',
                  marginRight: '8px',
                  marginLeft: '8px',
                  objectFit: 'cover',
                  borderRadius: '12px',
                  height: '209px',

                  filter:
                    !active && type === 'coming-soon' ? 'blur(8px)' : 'none',
                }}
                alt="launch-banner"
                loading="lazy"
              />
              {type !== 'coming-soon' && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    padding: '8px',
                    zIndex: 1,
                  }}
                >
                  <Grid container>
                    {chips?.map((chip, index) => (
                      <ChipText key={index} text={chip} color={'#B1B4B3'} />
                    ))}
                  </Grid>
                </Box>
              )}
            </Box>
          )}
        </>
        {/* )} */}

        <img
          src={titleImage}
          style={{
            filter: !active && type === 'coming-soon' ? 'blur(8px)' : 'none',
            height: '56px',
            width: '56px',
            marginRight: '10px',
            overflow: 'hidden',
            // borderRadius: '16px',
            marginTop: '-40px',
            position: 'relative',
            zIndex: 2,
            left: isDown767 ? '16px' : '32px',
            borderRadius: '12px',
            // border: '0.5px solid rgba(0, 0, 0, 0.5)',
          }}
          alt="launch-logo"
          loading="lazy"
        />
        <Box
          padding={isDown767 ? '0px 16px 40px 16px' : '0px 32px 24px'}
          style={{ position: 'relative', zIndex: 2 }}
        >
          <Typography
            fontWeight={isDown767 ? 500 : 600}
            fontSize={isDown767 ? 17 : 24}
            lineHeight={isDown767 ? '27.2px' : '31.2px'}
            fontFamily={isDown767 ? 'Inter' : 'Hellix'}
            color={'rgba(255, 255, 255, 1)'}
            paddingBottom={'16px'}
          >
            {
              // type === 'coming-soon' ? 'Coming Soon' :
              heading
            }
          </Typography>
          {type === 'coming-soon' ? (
            <Box
              gap={'14px'}
              display={'flex'}
              alignItems={'center'}
              flexWrap={'wrap'}
              flexDirection={'column'}
            >
              <SkeletonLoader
                firstLineWidth={78}
                secondLineWidth={50}
                thirdLineWidth={32}
              />

              <SkeletonLoader
                firstLineWidth={90}
                secondLineWidth={0}
                thirdLineWidth={39}
              />

              <SkeletonLoader
                firstLineWidth={138}
                secondLineWidth={0}
                thirdLineWidth={46}
              />

              <SkeletonLoader
                firstLineWidth={83}
                secondLineWidth={0}
                thirdLineWidth={23}
              />
            </Box>
          ) : (
            <>
              <Grid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  flexWrap={'wrap'}
                  gap={'8px'}
                >
                  <img src={TotalRaise} alt="total-raise" />
                  <Typography
                    fontFamily={'Inter'}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={'24px'}
                    color="#767676"
                  >
                    Total Raise
                  </Typography>
                </Box>
                <Typography
                  fontFamily={'Inter'}
                  fontWeight={500}
                  fontSize={14}
                  lineHeight={'24px'}
                  color={'#B1B4B3'}
                >
                  {totalRaise === -1 || !registrationStarted
                    ? 'TBA'
                    : totalRaise === -2
                    ? 'Launch Drop'
                    : `$${totalRaise?.toLocaleString('en')}`}
                </Typography>
              </Grid>

              <Grid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                marginTop={'10px'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  flexWrap={'wrap'}
                  gap={'8px'}
                >
                  <img src={EndsIn} alt="ends-in" />
                  <Typography
                    fontFamily={'Inter'}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={'24px'}
                    color="#767676"
                  >
                    {!started ? 'Starts In' : 'Ends In'}
                  </Typography>
                </Box>
                {registrationStarted && registrationEndDate ? (
                  <Countdown
                    date={registrationEndDate}
                    renderer={({
                      days,
                      hours,
                      minutes,
                      seconds,
                      completed,
                    }) => {
                      let _timerString = ''

                      if (days > 0) {
                        _timerString = `${days}d ${hours}h ${minutes}m ${seconds}s`
                      } else if (hours > 0) {
                        _timerString = `${hours}h ${minutes}m ${seconds}s`
                      } else {
                        _timerString = `${minutes}m ${seconds}s`
                      }
                      return (
                        <Typography
                          fontFamily={'Inter'}
                          fontWeight={600}
                          fontSize={14}
                          lineHeight={'16.8px'}
                          sx={{
                            alignSelf: 'center',
                            background: '#1d1f21',
                            height: '28px',
                            borderRadius: '60px',
                            padding: '5px 10px',
                          }}
                        >
                          <span
                            style={{
                              background:
                                'linear-gradient(90.22deg, #FF7722 0.16%, #F4FDAB 99.78%)',
                              backgroundClip: 'text',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                            }}
                          >
                            {completed ? 'Ended' : _timerString}
                          </span>
                        </Typography>
                      )
                    }}
                  />
                ) : timePassed && registrationStarted ? (
                  <Typography
                    fontFamily={'Inter'}
                    fontWeight={500}
                    fontSize={14}
                    lineHeight={'24px'}
                    color={'#ff7722'}
                  >
                    Started
                  </Typography>
                ) : (
                  <Typography
                    fontFamily={'Inter'}
                    fontWeight={500}
                    fontSize={14}
                    lineHeight={'24px'}
                    color={'#B1B4B3'}
                  >
                    {!started ? 'Coming Soon' : 'TBA'}
                  </Typography>
                )}
              </Grid>

              <Grid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                marginTop={'10px'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  flexWrap={'wrap'}
                  gap={'8px'}
                >
                  <img src={SaleType} alt="sale-type" />
                  <Typography
                    fontFamily={'Inter'}
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={'24px'}
                    color="#767676"
                  >
                    Sale Type
                  </Typography>
                </Box>
                <Typography
                  fontFamily={'Inter'}
                  fontWeight={500}
                  fontSize={14}
                  lineHeight={'32px'}
                  color={'#B1B4B3'}
                >
                  {registrationStarted ? saleType : 'TBA'}
                </Typography>
              </Grid>

              {backedByLogo ? (
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  marginTop={'10px'}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={'8px'}
                  >
                    <img src={MarketMaker} alt="market-maker" />
                    <Typography
                      fontFamily={'Inter'}
                      fontWeight={500}
                      fontSize={14}
                      lineHeight={'24px'}
                      color="#767676"
                    >
                      Backed by
                    </Typography>
                  </Box>
                  <img src={backedByLogo} />
                </Grid>
              ) : (
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  marginTop={'10px'}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={'8px'}
                  >
                    <img src={MarketMaker} alt="market-maker" />
                    <Typography
                      fontFamily={'Inter'}
                      fontWeight={500}
                      fontSize={14}
                      lineHeight={'24px'}
                      color="#767676"
                    >
                      Market Maker
                    </Typography>
                  </Box>
                  <Typography
                    fontFamily={'Inter'}
                    fontWeight={500}
                    fontSize={14}
                    lineHeight={'24px'}
                    color={'#B1B4B3'}
                  >
                    {registrationStarted ? marketMaker : 'TBA'}
                  </Typography>
                </Grid>
              )}
            </>
          )}

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {startsIn && timePassed && registrationStarted ? (
              <GreenButton
                fs={15}
                lh="22.5px"
                fm="Inter"
                fw={400}
                br="24px"
                text={'Participate Now'}
                mt={3}
                onClick={() => {
                  setProjectLinkSource('Current')
                  navigate(`/project/${routeName}`)
                }}
                outlined={true}
                w="200px"
              />
            ) : (
              // <GreenButton
              //   fs={17}
              //   lh="27px"
              //   fm="Inter"
              //   fw={500}
              //   disabled
              //   br="100px"
              //   text={buttonText ? buttonText : 'TBA'}
              //   mt={3}
              // />
              <Button
                sx={{
                  backgroundColor: 'transparent',
                  borderRadius: '24px',
                  padding: '14px 32px',
                  alignSelf: 'center',
                  border: '0.5px solid #313335',
                  width: '200px',
                  mt: 3,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    opacity: 0.8,
                  },

                  // disabled style
                  '&:disabled': {
                    backgroundColor: 'transparent',
                    cursor: 'not-allowed',
                  },
                }}
                variant="contained"
                disabled
              >
                {/* <img
                  src={GetNotified}
                  style={{
                    marginRight: '8px',
                  }}
                  alt="apply-icon"
                /> */}

                <Typography
                  fontSize={15}
                  fontWeight={400}
                  lineHeight={'21.5px'}
                  color="#FFFFFF"
                  textTransform={'capitalize'}
                >
                  Coming Soon
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NewUpcomingLaunchCard

export const ChipText = ({ text, color, isBigger }: any) => {
  const isDown1152 = useMediaQuery('(max-width:1152px)')

  return (
    <Box
      sx={{
        backgroundColor: '#141414',
        borderRadius: '60px',
        padding:
          isBigger && !isDown1152 ? '7px 12px 7px 12px' : '4px 8px 4px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mr: '4px',
      }}
    >
      <Typography
        fontWeight={400}
        fontSize={isBigger && !isDown1152 ? 15 : 12}
        lineHeight={'18px'}
        color={color}
        fontFamily={'Inter'}
      >
        {text}
      </Typography>
    </Box>
  )
}

const SkeletonLoader = ({
  firstLineWidth,
  secondLineWidth,
  thirdLineWidth,
}: any) => {
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      // marginTop={'10px'}
      height={'24px'}
      // mb={'11px'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        flexWrap={'wrap'}
        gap={'12px'}
      >
        <Skeleton variant="circular" width={16} height={16} animation={false} />
        <Skeleton
          variant="rectangular"
          width={firstLineWidth}
          height={12}
          animation={false}
        />
        <Skeleton
          variant="rectangular"
          width={secondLineWidth}
          height={12}
          animation={false}
        />
      </Box>
      <Skeleton
        variant="rectangular"
        width={thirdLineWidth}
        height={12}
        animation={false}
      />
    </Grid>
  )
}
